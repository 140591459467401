<template>
  <div class="mod">去应用商店</div>
  <ul id="last_right_b" class="last_right">
    <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
  </ul>
</template>

<script>
import Utils from '@/utils/utils'

export default {
  name: 'to_app',

  data () {
    return {
      is_ios: Utils.is_ios()
    }
  },
  mounted () {
    Utils.go_to_app('to_app', this.$route.query.sid)
  },
  methods: {
    xml_go_to_only (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>

</style>
